@tailwind base;
@tailwind components;
@tailwind utilities;

/* 


 MAIN

*/

@import url("https://fonts.googleapis.com/css2?family=Cambo&display=swap");

.scroll {
  overflow: hidden;
}

.hover-mail:hover {
  box-shadow: 0px 0px 10px 4px #f70042;
}

.cursor-ring {
  z-index: 50px;
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
  width: 20px;
  border: 2px #f70042 solid;
  height: 20px;
  background-color: #f70042;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 2px #f70042;
}

@media (max-width: 600px) {
  .cursor-ring {
    display: none;
  }
}

/* body {
  margin: 0; 
  padding: 0; 
  background-color: white; /* Set default background color */
/* } */

html {
  scroll-behavior: smooth;
}

body {
  max-height: 100vh;
}

/* 


 ONLOAD ANIMATION


*/

.animate__loading {
  position: absolute;
  bottom: 0;
  animation: load 3.2s ease-in-out forwards;
}

.animate__speed {
  animation: speed 1.4s ease-in-out forwards;
}

@keyframes load {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-130px);
  }
}

@keyframes speed {
  0% {
    transform: translateY(-130px);
  }
  100% {
    transform: translateY(-1000px);
  }
}

/* 


HOMEPAGE 


*/

.header {
  font-family: "Cambo", serif;
}

/* 


SCROLL BAR


*/

body {
  --sb-track-color: #232e33;
  --sb-thumb-color: #6baf8d;
  --sb-size: 16px;

  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
}

body::-webkit-scrollbar {
  width: var(--sb-size);
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
}

/* 


RESUME


*/

.menu {
  animation: menuOpen 300ms ease-in-out;
}

@keyframes menuOpen {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.menu-close {
  animation: menuClose 500ms ease-in-out;
}

@keyframes menuClose {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

body {
  cursor: none;
}

a {
  cursor: none;
}

.shadow__nav {
  display: flex;
  z-index: 40;
  position: fixed;
  top: 1.235em;
  border-radius: 38px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  align-items: center;
  height: 51px;
  padding: 4px 8px;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
}

.shadow {
  box-shadow: 0px 0px 14px 0 #f70042;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.about__color {
  background: linear-gradient(
    300deg,
    #ffee07,
    #ff2b6a,
    #ab4bff,
    #5ca2ff,
    #df1700
  );
  background-size: 360% 360%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 30s ease infinite;
}

.overflow {
  overflow: hidden;
}
